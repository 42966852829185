/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-west-1",
    "aws_cloud_logic_custom": [
        {
            "name": "admin",
            "endpoint": "https://em3id1ozc8.execute-api.eu-west-1.amazonaws.com/prod",
            "region": "eu-west-1"
        },
        {
            "name": "chesscom",
            "endpoint": "https://foi5xfyoje.execute-api.eu-west-1.amazonaws.com/prod",
            "region": "eu-west-1"
        },
        {
            "name": "chessplayers",
            "endpoint": "https://ms60qr0ljj.execute-api.eu-west-1.amazonaws.com/prod",
            "region": "eu-west-1"
        },
        {
            "name": "events",
            "endpoint": "https://6f2p1gg1rk.execute-api.eu-west-1.amazonaws.com/prod",
            "region": "eu-west-1"
        },
        {
            "name": "lichess",
            "endpoint": "https://2r0iujzvub.execute-api.eu-west-1.amazonaws.com/prod",
            "region": "eu-west-1"
        },
        {
            "name": "players",
            "endpoint": "https://s872pvupyf.execute-api.eu-west-1.amazonaws.com/prod",
            "region": "eu-west-1"
        },
        {
            "name": "public",
            "endpoint": "https://zkxiggnvb5.execute-api.eu-west-1.amazonaws.com/prod",
            "region": "eu-west-1"
        },
        {
            "name": "social",
            "endpoint": "https://iqpx9xxd1i.execute-api.eu-west-1.amazonaws.com/prod",
            "region": "eu-west-1"
        },
        {
            "name": "travel",
            "endpoint": "https://1mif6e1fj6.execute-api.eu-west-1.amazonaws.com/prod",
            "region": "eu-west-1"
        }
    ],
    "aws_appsync_graphqlEndpoint": "https://ratuebbeqjdwzgk3ibmqy4t6zu.appsync-api.eu-west-1.amazonaws.com/graphql",
    "aws_appsync_region": "eu-west-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_appsync_apiKey": "da2-rrspezdkejceriwn2olc6gdspa",
    "aws_cognito_identity_pool_id": "eu-west-1:b60703b2-1b24-4ecf-abc9-fdb7f714b1f8",
    "aws_cognito_region": "eu-west-1",
    "aws_user_pools_id": "eu-west-1_yAub13Ns7",
    "aws_user_pools_web_client_id": "5u2slpke53artl5krmbq5pk38d",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": [
            "REQUIRES_LOWERCASE",
            "REQUIRES_NUMBERS",
            "REQUIRES_SYMBOLS",
            "REQUIRES_UPPERCASE"
        ]
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "pgn-bucket44508-prod",
    "aws_user_files_s3_bucket_region": "eu-west-1"
};


export default awsmobile;
